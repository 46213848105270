<template>
  <div
    class="w-[26rem] max-w-full flex border border-black-10 shadow-sm rounded-s text-sm relative"
  >
    <div class="w-full grow-1 bg-white rounded-l-s">
      <UiDatepicker
        class="inline-block w-full"
        :fixed-width="true"
        :show-size="true"
        @blur="(newVal) => (newLocalDatePickerVisible = newVal)"
        @show-size="(newVal) => (newLocalSizePickerVisible = newVal)"
      >
        <template #trigger>
          <div class="flex divide-x divide-black-10 border-r border-r-[#e8e8e8]">
            <div
              class="flex rounded-s-s grow hover:bg-black-4 transition-colors"
              :class="{ 'bg-black-4': newLocalDatePickerVisible }"
            >
              <input
                class="w-full shrink font-sans text-center px-3 py-3 rounded-l-s appearance-none outline-0 bg-[transparent] text-black cursor-pointer placeholder:text-black-50"
                :class="{ 'text-purple': newLocalDatePickerVisible }"
                type="text"
                :placeholder="$t('search.date')"
                v-model="niceDate"
                readonly="true"
              />
              <button
                v-if="niceStartDate && newLocalDatePickerVisible"
                @click="clearDate"
                class="h-full px-3 shrink-0 absolute right-0 top-0 flex items-center justify-center hover:opacity-70 transition-opacity"
              >
                <img src="/img/close.svg" alt="" class="w-4 h-4 shrink-0" />
              </button>
            </div>
          </div>
        </template>
      </UiDatepicker>
    </div>
    <div class="flex divide-x divide-black-10">
      <input
        class="h-full w-14 shrink-0 bg-black bg-[url('/img/search.svg')] bg-no-repeat bg-center cursor-pointer text-0 rounded-r-s rounded-s-none"
        type="submit"
        value=""
        @click="submitVehicleSearch"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
const { locale } = useI18n();
const localePath = useLocalePath();
const router = useRouter();

const newLocalSizePickerVisible = ref(false);
const newLocalDatePickerVisible = ref(false);

const { startDate, endDate, searchDate } = useSearch();

const niceDate = computed(() => {
  if (!startDate.value || !endDate.value) return null;
  const startMonth = useDateFormat(startDate.value, 'MM').value;
  const endMonth = useDateFormat(endDate.value, 'MM').value;
  const startYear = useDateFormat(startDate.value, 'YYYY').value;
  const endYear = useDateFormat(endDate.value, 'YYYY').value;
  var date;
  if (startYear === endYear && startMonth === endMonth) {
    date = `${useDateFormat(startDate.value, 'D.', { locales: locale.value ?? 'de' }).value} - ${
      useDateFormat(endDate.value, 'D. MMMM YYYY', { locales: locale.value ?? 'de' }).value
    }`;
  } else if (startYear === endYear) {
    date = `${useDateFormat(startDate.value, 'D.M.', { locales: locale.value ?? 'de' }).value} - ${
      useDateFormat(endDate.value, 'D.M.YYYY', { locales: locale.value ?? 'de' }).value
    }`;
  } else {
    date = `${
      useDateFormat(startDate.value, 'D.M.YYYY', { locales: locale.value ?? 'de' }).value
    } - ${useDateFormat(endDate.value, 'D.M.YYYY', { locales: locale.value ?? 'de' }).value}`;
  }

  return date;
});

const niceStartDate = computed(() => {
  if (!startDate.value) return undefined;
  return useDateFormat(startDate.value, 'DD.MM.YYYY', {
    locales: locale.value ?? 'de',
  }).value;
});
const niceEndDate = computed(() => {
  if (!endDate.value) return undefined;
  return useDateFormat(endDate.value, 'DD.MM.YYYY', {
    locales: locale.value ?? 'de',
  }).value;
});

function submitVehicleSearch() {
  router.push(localePath({ name: 'cars' }));
}
const clearDate = () => {
  searchDate.value = [];
};
// const clearSize = () => {
//   vehicleTypes.value = [];
// };
</script>
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: 0.1s cubic-bezier(0.4, 0, 1, 1);
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
  transform: translateY(-10%);
}
</style>
